<!-- AdminLayout.vue -->
<template>
    <section class="community event">
         <router-view></router-view>
    </section>
  </template>
  
  <script>
  
  export default {
    name: "UserLayout",
    components: { 
         
    },
  };
  </script>
  