import { createRouter, createWebHistory } from 'vue-router'
import UserLayout from '../layout/UserLayout.vue'

import store from "../store";

import auth from '../middleware/auth'
import middlewarePipeline from './middlewarePipeline'

const routes = [
  // **************** User Route **************
  {
    path: "/",
    name: "HomePage",
    component: UserLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'User'
    },
    children: [
      {
        path: "",
        name: "communityPage",
        component: () => import("../views/user/community/Community.vue"),
        meta: { authRequired: false, title: 'Community | Bali Business Club' },
      },
      {
        path: "/member-details=:id",
        name: "MemberDetailsPage",
        component: () => import("../views/user/community/MemberDetails.vue"),
        meta: { authRequired: false, title: 'Member Details | Bali Business Club' },
      },
       //Events
      {
        path: "/events",
        name: "EventsPage",
        component: () => import("../views/user/events/Events.vue"),
        meta: { authRequired: false, title: 'Events | Bali Business Club' },
      },
      {
        path: "/event-details=:id",
        name: "EventsDetailsPage",
        component: () => import("../views/user/events/EventDetails.vue"),
        meta: { authRequired: false, title: 'Event Details | Bali Business Club' },
      },
      {
        path: "/payment",
        name: "PaymentPage",
        component: () => import("../views/user/events/stripeCheckout.vue"),
        meta: { authRequired: false, title: 'Payment | Bali Business Club' },
      },
      {
        path: "/all-events",
        name: "AllEventsPage",
        component: () => import("../views/user/events/AllEvents.vue"),
        meta: { authRequired: false, title: 'All Event | Bali Business Club' },
      },
      //Services
      {
        path: "/services",
        name: "ServicesPage",
        component: () => import("../views/user/services/Services.vue"),
        meta: { authRequired: false, title: 'Services | Bali Business Club' },
      },
      {
        path: "/service-details=:id",
        name: "ServiceDetailsPage",
        component: () => import("../views/user/services/ServiceDetails.vue"),
        meta: { authRequired: false, title: 'Service Details | Bali Business Club' },
      },
      {
        path: "/all-service",
        name: "AllServicesPage",
        component: () => import("../views/user/services/AllServices.vue"),
        meta: { authRequired: false, title: 'All Services | Bali Business Club' },
      },
        //Profile
      {
        path: "/profile",
        name: "ProfilePage",
        component: () => import("../views/user/profile/Profile.vue"),
        meta: { authRequired: false, title: 'Profile | Bali Business Club' },
      },
      {
        path: "/edit-profile",
        name: "EditProfilePage",
        component: () => import("../views/user/profile/EditProfile.vue"),
        meta: { authRequired: false, title: 'Edit Profile | Bali Business Club' },
      },
    ]
  },

  //Login page
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("../views/user/Login.vue"),
    meta: { authRequired: false, title: 'Bali Business Club' },
  },
  {
    path: "/signup",
    name: "SignupPage",
    component: () => import("../views/user/Signup.vue"),
    meta: { authRequired: false, title: 'Bali Business Club' },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/user/ForgotPassword.vue"),
    meta: { authRequired: false, title: 'Forgot Password | Bali Business Club' },
  },
  {
    path: "/reset-password&id=:id",
    name: "ResetPassword",
    component: () => import("../views/user/ResetPassword.vue"),
    meta: { authRequired: false, title: 'Reset Password | Bali Business Club' },
  },
   // *********404 Page **********
   {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/partials/notFound.vue"),
      meta: {title: 'Not Found | Bali Business Club' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Bali Business Club';
  window.scrollTo(0, 0);

  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  console.log('router * middleware', middleware);
  const context = {
      to,
      from,
      next,
      store
  }

  return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
});

export default router
