// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/services';
// import router from "../../../router/routes";

const state = {
    // Additional state if needed
    servicesData: [],
    popularServicesData: [],
    serviceByIdData: [],
}

const mutations = {
    SET_SERVICES_DATA(state, data) {
        state.servicesData = data
    },
    SET_SERVICES_DATA_BY_POPULARITY(state, data) {
        state.popularServicesData = data
    },
    SET_SERVICE_BY_ID_DATA(state, data) {
        state.serviceByIdData = data
    },
}

const actions = {
    // Additional actions if needed

    getServices({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_SERVICES,{
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getServices res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_SERVICES_DATA', res.data)
                        const popularServices = res.data.filter((element) => element.popularity === 'high');
                        commit('SET_SERVICES_DATA_BY_POPULARITY', popularServices);
                        // console.log("popular", popularServices);
                      }
                      else{
                        commit('SET_SERVICES_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getServices err', err)
                  })
          // })
    },

    getServiceById({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_SERVICE_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getServiceById res', res)
                      if(Array.isArray(res.data.data)){
                        commit('SET_SERVICE_BY_ID_DATA', res.data.data[0])
                      }
                      else{
                        commit('SET_SERVICE_BY_ID_DATA', res.data.data[0].message)
                      }
                  })
                  .catch((err) => {
                      console.log('getServiceById err', err)
                  })
          // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };