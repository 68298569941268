import { createStore } from 'vuex';
// import App from "../../src/app.vue";
//auth and toast
import auth from './auth.js';
import VerifyErrorsAndToasts from './verifyErrorsAndToasts.js';

//user
import community from './module/user/community.js';
import event from './module/user/event.js';
import services from './module/user/services.js';
import profile from './module/user/profile.js';
import search from './module/user/search.js';

export default createStore({
  modules: {
    // auth and toast
    auth,
    VerifyErrorsAndToasts,

    // user
    community,
    event,
    services,
    profile,
    search,

  },
});