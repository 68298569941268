// store/auth.js

// import * as dashboard from './user';
// import axios from "axios";
// import { apis } from '../../apis/community';
// import router from "../../../router/routes";

const state = {
    // Additional state if needed
    searchQuery: '',
}

const mutations = {
    setSearchQuery(state, query) {
      console.log("query", query)
      state.searchQuery = query;
    },
  
    clearSearchQuery(state) {
      state.searchQuery = '';
    },
  
}

const actions = {
    // Additional actions if needed
}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };