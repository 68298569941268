import router from "../router/routes";

// Common APIs
const state = {
    errorAndSuccessMessage: "",
    toasts: [],
};

const mutations = {
    CHECK_ERROR_AND_SUCCESS_MESSAGE(state, Message) {
        state.errorAndSuccessMessage = Message;
      },                                
  addToast(state, toast) {
    state.toasts.push(toast);
    // console.log('addToast-',state,toast)
  },
  clearToast(state, title) {
    // console.log('clearToast-',state,title)
    setTimeout(() => {
      if (title != undefined) {
        const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
        state.toasts.splice(index, 1); // remove toast from array
      } else {
        state.toasts.splice(0, 1); // remove toast from array
      }
    }, 5000);
  },
  clearToast2(state, title) {
    if (title != undefined) {
      const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
      state.toasts.splice(index, 1); // remove toast from array
    } else {
      state.toasts.splice(0, 1); // remove toast from array
    }
  },
};

const actions = {
    checkErrorAndSendToast({ commit, rootGetters }, response) {
        commit("CHECK_ERROR_AND_SUCCESS_MESSAGE", response[0]);
        console.log("message-1-", response);
        // console.log("check-1-", response[1]);
        const message = response[0];
  
        if (response[1] == "error") {
          if (message == undefined) {
            commit("addToast", {
              title: "Server Error",
              type: "error_1",
              message:
                "May be server are not working, please try again after few moment",
            });
            commit(
              "clearToast",
              "May be server are not working, please try again after few moment"
            );
            return;
          } else if (message.statusText == "Unauthorized") {
            console.log('Unauthorized-1-',message.statusText);
            commit("addToast", {
              title: "Faild",
              type: "error_1",
              message: message.data.message,
            });
            commit("clearToast", message.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // alert("Your login session has been expired.");
            // router.go();
          } else if (message.statusText == "Unauthenticated") {
            // commit("addToast", {
            //   title: "Faild",
            //   type: "error_1",
            //   message: message.data.message,
            // });
            // commit("clearToast", message.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // alert("Your login session has been expired.");
            // router.go();
          } else if (message.status === 429) {
            commit("addToast", {
              title: "Faild",
              type: "error_1",
              message: message.statusText + ", try few moment later",
            });
            commit("clearToast", message.statusText + ", try few moment later");
            return;
          } else if (message.status === 500) {
            commit("addToast", {
              title: "Faild",
              type: "error_1",
              message: message.statusText + ", try few moment later",
            });
            commit("clearToast", message.statusText + ", try few moment later");
            // router.push("*");
            return;
          }
          // commit("addToast", {
          //   title: "Faild",
          //   type: "error_1",
          //   message: message.data.message,
          // });
          // setTimeout(() => {
          //   commit("clearToast", message.data.message);
          // }, 5000);
          else if (
            (message.data.message == "Error validation" ||
              message.data.message == "Error Validation") &&
            message.data.data
          ) {
            const errorData = message.data.data;
  
            Object.keys(errorData).forEach((key) => {
              const errorArray = errorData[key];
              if (Array.isArray(errorArray)) {
                commit("addToast", {
                  title: "Faild",
                  type: "error_1",
                  message: `${key} error :- ` + errorArray[0] + ", type again",
                });
                // setTimeout(() => {
                commit(
                  "clearToast",
                  `${key} error :- ` + errorArray[0] + ", type again"
                );
                // }, 10000);
                console.log(`${key} Errors:`, errorArray);
              }
            });
          } else if (message.status == 404) {
            if (message.data.message == "Quote does not exists.") {
              commit("addToast", {
                title: "Not Found",
                type: "error_1",
                message:
                  message.data.message +
                  " May be this quote canceled by KonferenceBokarna Or Customer",
              });
              commit(
                "clearToast",
                message.data.message +
                  " May be this quote canceled by KonferenceBokarna Or Customer"
              );
              return;
            } else {
              commit("addToast", {
                title: message.statusText,
                type: "error_1",
                message: message.data.message
                  ? message.data.message
                  : message.statusText +
                    "May be server are not working, please try again after few moment",
              });
              commit(
                "clearToast",
                message.data.message
                  ? message.data.message
                  : message.statusText +
                      "May be server are not working, please try again after few moment"
              );
              return;
            }
          } else {
            commit("addToast", {
              title: "Faild",
              type: "error_1",
              message: message.data.message,
            });
            commit("clearToast", message.data.message);
          }
        } else {
          commit("addToast", {
            title: "Success",
            type: "success_1",
            message: message.data.message,
          });
          commit("clearToast", message.data.message);
        }
      },
};

const getters = {

};

export default {
  state,
  mutations,
  actions,
  getters,
};
