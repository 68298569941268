import axios from "axios";
import router from "@/router/routes";

export default function auth({ to, from, next, store }) {
  var token = localStorage.getItem("token");
  var isAuthenticated = Boolean(token);
  // console.log("isAuthenticated middelware--", isAuthenticated);
  // console.log("isAuthenticated store--", store);
  // console.log("isAuthenticated next--", next);
  console.log("isAuthenticated from--", from);
  // console.log("isAuthenticated to--", to);
  // console.log("isAuthenticated meta.portal--", to.meta.portal);

  if (isAuthenticated) {
    if (store.getters.auth) {
      //console.log('authenticate');
      next();
    } else {
      var formData2 = new FormData();
      formData2.append("token", token);
      axios
        .post(axios.defaults.baseURL + "api/get_current_logged_user", formData2, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          console.log("login details--from auth middleware--", response);
          const Response = response.data.data.user[0]

          if (Response.roles == "0" || Response.roles === 0) {
            let user = {
              created_at: Response.created_at,
              d_où_viens_tu: Response.d_où_viens_tu,
              depuis_combien_de_temps: Response.depuis_combien_de_temps,
              email: Response.email,
              email_verified_at: Response.email_verified_at,
              es_tu_installé_à_Bali: Response.es_tu_installé_à_Bali,
              first_name: Response.first_name,
              instagram: Response.instagram,
              last_name: Response.last_name,
              linkedin: Response.linkedin,
              name: Response.name,
              pour_combien_de_temps_seras_tu_à_Bali: Response.pour_combien_de_temps_seras_tu_à_Bali,
              profile_pic: Response.profile_pic,
              pseudo: Response.pseudo,
              roles: Response.roles,
              tes_hobbies_à_bali: Response.tes_hobbies_à_bali,
              tes_meilleures_skills: Response.tes_meilleures_skills,
              facebook: Response.facebook,
              ton_parcours: Response.ton_parcours,
              updated_at: Response.updated_at,
              user_id: Response.user_id,
              wpnumber: Response.wpnumber,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "UserLayout" });
            localStorage.setItem("user", JSON.stringify(user));
            if(user.roles == '0' || user.roles == 0){
              router.push("/")
             }
          }

          next();
        })
        .catch((err) => {
          console.log("error hai routes--", err);
          if ("message" in err) {
            alert(err.message);
          }
          if (err.response.statusText == "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return next({
              name: "LoginPage",
            });
          }
        });
    }
  } else if (to.meta.portal == "User") {
    //console.log("Logout")
    // store.dispatch("logOut").then(() => {
    //   next("/login");
    // });

    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    store.commit("SET_COMPONENT_LAYOUT", { layout: "UserLayout" });
    return next({
      name: "LoginPage",
    });
  }
}
